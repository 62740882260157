import "./normal.css";
import "./App.css";
import { useState, useRef, useEffect } from "react";
import Avatar from "./components/Avatar";
import NewChat from "./components/NewChat";
import NavPrompt from "./components/NavPrompt";
import Loading from "./components/Loading";
import Error from "./components/Error";
import NavLinks from "./components/NavLink";
import BotResponse from "./components/BotResponse";
import IntroSection from "./components/IntroSection";
import { BsMic } from "react-icons/bs";
import axios from "axios";
import {encodebody, getDecodedBody} from "./utils/utils.js";
// import GenerateImage from "./GenerateImage";
import { BsMicFill } from "react-icons/bs";
import { BsMicMuteFill } from "react-icons/bs";


// import { MdSend } from "react-icons/md";
import { IoSendSharp } from "react-icons/io5";
function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);

  const [setIsImageGenerated] = useState(false);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatLog]);

  
  // get the string of the called url, to get the chatbotkey parameter
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const chatbotkey = params.get("chatbotkey");
  const  descriptivename = params.get("descriptivename");
  const  clientnr = params.get("clientnr");
  var mygwoken = params.get("gwoken");
  var myE2EE = params.get("E2EE"); 
  var gwokutoken = params.get("gwokutoken"); 

  // if a parameter chatbot key is passed, use that one instead
  if (mygwoken==null)
  {
    mygwoken = process.env.REACT_APP_GWOKEN;
  }

  var gwoken = JSON.parse(mygwoken);



  if (myE2EE==null)
  {
    myE2EE = process.env.REACT_APP_E2EE;
  }

  var E2EE = JSON.parse(myE2EE);

  if (gwokutoken==null)
  {
    gwokutoken = process.env.REACT_APP_GWOKENTOKEN;
  }


  var clientNr = process.env.REACT_APP_CLIENTNR;
  // if a parameter chatbot key is passed, use that one instead
  if (clientnr!=null)
  {
    clientNr = clientnr;
  }

  var clientNr = process.env.REACT_APP_CLIENTNR;
  // if a parameter chatbot key is passed, use that one instead
  if (clientnr!=null)
  {
    clientNr = clientnr;
  }

  // set chatbot to ENV variable
  var chatbotKey = process.env.REACT_APP_CHATBOTKEY;
  // if a parameter chatbot key is passed, use that one instead
  if (chatbotkey!=null)
  {
    chatbotKey = chatbotkey;
  }

  var descriptiveName = process.env.REACT_APP_DESCRIPTIVENAME;
  // if a parameter chatbot key is passed, use that one instead
  if (descriptivename!=null)
  {
    descriptiveName = descriptivename;
  }

  var clientNr = process.env.REACT_APP_CLIENTNR;
  // if a parameter chatbot key is passed, use that one instead
  if (clientnr!=null)
  {
    clientNr = clientnr;
  }

  const baseurl = process.env.REACT_APP_URL;
  

  // console.log("Parametres are:")
 //  console.log(baseurl);
  // console.log(clientNr);
  // console.log(gwoken);
  // console.log(chatbotKey);

  


  const handleSubmit = (e) => {
    e.preventDefault();
    setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
   

    var mybody = {
      clientNr: clientNr,
      chatbotKey: chatbotKey,
      prompt: inputPrompt
    } 



    async function callAPI() {
      try {
        console.log("before entering encodebody");
        const body = encodebody(gwoken,gwokutoken,E2EE,mybody);
        console.log("After encodebody");
        console.log("BODY");
        console.log(body);
        
        const response = await axios.post( baseurl+ "/api/chat/ask", body);
      
        // const data = "yes how are you?"
         // const data = response;
        setChatLog([
          ...chatLog,
          {
            chatPrompt: inputPrompt,
            botMessage: getDecodedBody(response.data)
          },
        ]);
        setErr(false);
      } catch (err) {
        console.log("There was an error returned form the chat")
        setErr(err.reponse.data);
      }
    }
    callAPI();
    setInputPrompt("");
  };
  const API_KEY = process.env.REACT_APP_IMAGES_KEY;
  // const [text, setText] = useState('');
  const [imageURL, setImageURL] = useState("");

  // const generateImage = async () => {
  //   if (inputPrompt.startsWith("draw")) {
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: "https://api.openai.com/v1/images/generations",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${API_KEY}`,
  //         },
  //         data: {
  //           model: "image-alpha-001",
  //           prompt: inputPrompt,
  //           num_images: 1,
  //           size: "512x512",
  //           response_format: "url",
  //         },
  //       });
  //       setImageURL(response.data.data[0].url);
  //       setIsImageGenerated(true);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  return (
    <div className="App">
      <header>
        <div className="menu">
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <h1 style={{ paddingRight: "40px" }}>The Venue</h1>
      </header>
      {showMenu && (
        <nav>
          <div className="navItems">
            <NewChat setChatLog={setChatLog} setShowMenu={setShowMenu} />
            {chatLog.map(
              (chat, idx) =>
                chat.botMessage && (
                  <NavPrompt chatPrompt={chat.chatPrompt} key={idx} />
                )
            )}
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}

      <aside className="sideMenu">
        <NewChat setChatLog={setChatLog} setShowMenu={setShowMenu} />
        <div className="navPromptWrapper">
          {chatLog.map(
            (chat, idx) =>
              chat.botMessage && (
                <NavPrompt chatPrompt={chat.chatPrompt} key={idx} />
              )
          )}
        </div>
        {chatLog.length > 0 && (
          <NavLinks
            svg={
              <svg
                fill="#fff"
                viewBox="0 0 24 24"
                data-name="Flat Line"
                xmlns="http://www.w3.org/2000/svg"
                className="icon flat-line"
                stroke="#fff"
                width={23}
                height={23}
              >
                <path
                  d="M5 8h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5V8Z"
                  transform="rotate(90 12 14)"
                  style={{
                    fill: "#fff202022",
                    strokeWidth: 2,
                  }}
                />
                <path
                  d="M16 7V4a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3"
                  style={{
                    fill: "none",
                    stroke: "#fff202022000000",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: 2,
                  }}
                />
                <path
                  data-name="primary"
                  d="M10 11v6m4-6v6M4 7h16m-2 13V7H6v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1Z"
                  style={{
                    fill: "none",
                    stroke: "#fff202022000000",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: 2,
                  }}
                />
              </svg>
            }
            text="Clear Conversations"
            setChatLog={setChatLog}
          />
        )}
        <NavLinks
          svg={
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
              stroke="#fff"
              width={25}
              height={25}
            >
              <title>{"discord_fill"}</title>
              <g stroke="none" fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
                <path
                  d="M15.003 4c.744 0 1.53.26 2.25.547l.527.216c1.26.528 1.968 1.636 2.517 2.853.891 1.975 1.51 4.608 1.724 6.61.102.95.127 1.906-.056 2.549-.197.687-.867 1.173-1.518 1.555l-.322.183-.334.186c-.172.096-.349.191-.525.284l-.522.27-.717.357-.577.284a1 1 0 1 1-.894-1.788l.79-.39-.58-.609c-1.39.57-3.027.893-4.766.893-1.739 0-3.376-.322-4.766-.893l-.58.608.793.39a1 1 0 1 1-.894 1.79l-.544-.27c-.402-.2-.805-.398-1.203-.607l-.928-.505-.321-.183c-.651-.382-1.322-.868-1.518-1.555-.184-.643-.158-1.598-.057-2.55.214-2.001.833-4.634 1.724-6.609.549-1.217 1.257-2.325 2.517-2.853C7.059 4.413 8.072 4 9 4c.603 0 1.077.555.99 1.147A13.65 13.65 0 0 1 12 5c.691 0 1.366.05 2.014.148A1.012 1.012 0 0 1 15.004 4ZM8.75 10.5a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Zm6.5 0a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Z"
                  fill="#fff"
                />
              </g>
            </svg>
          }
          // text="OpenAI Discord"
          // link="https://discord.com/invite/openai"
        />
        <NavLinks
          svg={
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width={25}
              height={25}
            >
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6H7a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-5m-6 0 7.5-7.5M15 3h6v6"
              />
            </svg>
          }
          // text="Updates & FAQ"
          // link="https://help.openai.com/en/collections/3742473-chatgpt"
        />
      </aside>

      <section className="chatBox">
      <h1 style={{color: "white"}}>{descriptiveName}</h1>
        {chatLog.length > 0 ? (
          <div className="chatLogWrapper">
            {chatLog.length > 0 &&
              chatLog.map((chat, idx) => (
                <div className="chatLog" key={idx}>
                  <div className="chatPromptMainContainer">
                    <div className="chatPromptWrapper">
                      <Avatar bg="#5437DB" className="userSVG">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={1.9}
                          viewBox="0 0 24 24"
                          // strokeLinecap="round"
                          // strokeLinejoin="round"
                          className="h-6 w-6"
                          height={40}
                          width={40}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                      </Avatar>
                      <div id="chatPrompt">{chat.chatPrompt}</div>
                    </div>
                  </div>

                  <div className="botMessageMainContainer">
                    <div className="botMessageWrapper">
                      <Avatar bg="#11a27f" className="openaiSVG">
                        <svg
                          width={41}
                          height={41}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth={1.5}
                          className="h-6 w-6"
                          
                        >
                          <image href="/logo192.png" x="-2" y="0" width="50" height="50" />
                        </svg>
                      </Avatar>

                      {chat.botMessage ? (
                        <div id="botMessage" ref={messagesEndRef}>
                        
                          {/* <img src={imageURL}  /> */}

                          
                          <BotResponse response={chat.botMessage} />

                        {/* <GenerateImage/> */}
                          
                        </div>
                      ) : err ? (
                        <Error err={err} />
                      ) : (
                        <Loading />
                      )}










                    </div>
                  </div>
                </div>
              
              ))}
          </div>
         
        ) : (
          <IntroSection />
        )}
      <form onSubmit={handleSubmit}>
  <div className="inputPromptWrapper">
    <div className="input-group" style={{ flexGrow: 1}}>
      <textarea
        name="inputPrompt"
        autoComplete="off"
        id=""
        className="inputPrompttTextarea"
        type="text"
        rows={4}
        value={inputPrompt}
        onChange={(e) => setInputPrompt(e.target.value)}
        autoFocus
      ></textarea>
      </div>
      <div>
        <button
        type="submit"
        style={{
          backgroundColor: "#41414E",
          color: "white",
          border: 0,
          padding: "4px 22px",
          fontSize: "16px",
          margin: "10px 0 0 0",
          float: "left"
        
        }}
      >
        <IoSendSharp />
      </button>
    </div>
    <p></p>
  </div>
</form>

        
      </section>
    </div>
  );
}

export default App;
