import React from "react";
const IntroSection = () => {
  return (
    <div id="introsection">
      <h1>This is our AI Venue. Find your answers immediately.</h1>
      <p>
        Features:
        <ul>
          <li>Instantaneous responses to all your questions</li>
          <li>Trained on our knowledgebase</li>
        </ul>
      </p>
    </div>
  );
};

export default IntroSection;
